import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("奖项")]), _c("a-form", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      selfUpdate: true,
      form: _vm.createForm,
      layout: "horizontal",
      labelCol: {
        style: "min-width: 140px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "奖品名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入奖项名称"
        }],
        initialValue: _vm.info.name,
        whitespace: true
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入奖项名称',\n                                },\n                            ],\n                            initialValue: info.name,\n                            whitespace: true,\n                        },\n                    ]"
    }],
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖品图片"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["img", {
        rules: [{
          required: true,
          message: "请上传图片！"
        }],
        initialValue: ""
      }],
      expression: "[\n                        'img',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请上传图片！',\n                                },\n                            ],\n                            initialValue: ''\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion,
      disabled: _vm.isView
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖品展示价格($)"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["price", {
        rules: [{
          required: true,
          validator: _vm.handlePriceRule
        }],
        initialValue: _vm.info.price
      }],
      expression: "[\n                        'price',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    validator: handlePriceRule,\n                                },\n                            ],\n                            initialValue: info.price,\n                        },\n                    ]"
    }],
    attrs: {
      type: "number",
      autocomplete: "off",
      size: "large",
      min: 0.01,
      disabled: _vm.isFormDisabled(),
      placeholder: "请输入奖品价格"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖券总量"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ticket_num", {
        rules: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback("请输入奖券总量");
            } else if (!/^[1-9][0-9]*$/.test(value)) {
              callback("奖券总量只能是1-99999999间的整数");
            }
            callback();
          }
        }],
        initialValue: _vm.info.ticket_num
      }],
      expression: "[\n                        'ticket_num',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    validator: function(rule,value,callback){\n                                        if(!value){\n                                            callback('请输入奖券总量')\n                                        }else if(!/^[1-9][0-9]*$/.test(value)){\n                                            callback('奖券总量只能是1-99999999间的整数')\n                                        }\n                                        callback();\n                                    }\n                                },\n                            ],\n                            initialValue: info.ticket_num,\n                        },\n                    ]"
    }],
    attrs: {
      type: "number",
      autocomplete: "off",
      size: "large",
      min: 1,
      max: 99999999,
      disabled: _vm.isFormDisabled(),
      placeholder: "请输入奖券总量"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖券展示总量"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ticket_show_num", {
        rules: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback("请输入奖券展示总量");
            } else if (!/^[1-9][0-9]*$/.test(value)) {
              callback("奖券展示总量只能是1-99999999间的整数");
            }
            callback();
          }
        }],
        initialValue: _vm.info.ticket_show_num
      }],
      expression: "[\n                        'ticket_show_num',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    validator: function(rule,value,callback){\n                                        if(!value){\n                                            callback('请输入奖券展示总量')\n                                        }else if(!/^[1-9][0-9]*$/.test(value)){\n                                            callback('奖券展示总量只能是1-99999999间的整数')\n                                        }\n                                        callback();\n                                    }\n                                },\n                            ],\n                            initialValue: info.ticket_show_num,\n                        },\n                    ]"
    }],
    attrs: {
      type: "number",
      autocomplete: "off",
      size: "large",
      min: 1,
      max: 99999999,
      disabled: _vm.isFormDisabled(),
      placeholder: "请输入奖券展示总量"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "个人奖券总上限"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["limit_num", {
        rules: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback("请输入个人奖券总上限");
            } else if (!/^[1-9][0-9]*$/.test(value)) {
              callback("个人奖券总上限只能是1-99999999间的整数");
            }
            callback();
          }
        }],
        initialValue: _vm.info.limit_num
      }],
      expression: "[\n                        'limit_num',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    validator: function(rule,value,callback){\n                                        if(!value){\n                                            callback('请输入个人奖券总上限')\n                                        }else if(!/^[1-9][0-9]*$/.test(value)){\n                                            callback('个人奖券总上限只能是1-99999999间的整数')\n                                        }\n                                        callback();\n                                    }\n                                },\n                            ],\n                            initialValue: info.limit_num,\n                        },\n                    ]"
    }],
    attrs: {
      type: "number",
      autocomplete: "off",
      size: "large",
      min: 1,
      max: 99999999,
      disabled: _vm.isFormDisabled(),
      placeholder: "请输入个人奖券总上限"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "开始时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["start_time", {
        rules: [{
          required: true,
          message: "请选择开始日期"
        }],
        initialValue: !_vm.info.start_time ? null : _vm.moment(_vm.info.start_time, "YYYY-MM-DD HH:mm:ss")
      }],
      expression: "[\n                        'start_time',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择开始日期',\n                                },\n                            ],\n                            initialValue: !info.start_time ? null : moment(info.start_time, 'YYYY-MM-DD HH:mm:ss')\n                        },\n                    ]"
    }],
    attrs: {
      format: "YYYY-MM-DD HH:mm:ss",
      disabled: _vm.isFormDisabled(),
      "disabled-date": _vm.disabledDate,
      showTime: true,
      size: "large"
    }
  })], 1), _c("a-form-item", {
    staticClass: "content-line1"
  }, [_c("label", {
    class: _vm.endtime == 0 ? "ant-form-item-required" : "",
    attrs: {
      slot: "label",
      title: "结束时间"
    },
    slot: "label"
  }, [_vm._v("结束时间")]), _c("div", [_c("a-radio-group", {
    attrs: {
      size: "large",
      name: "radioGroup",
      placeholder: "请选择",
      disabled: _vm.isFormDisabled()
    },
    on: {
      change: _vm.endtimeChange
    },
    model: {
      value: _vm.endtime,
      callback: function callback($$v) {
        _vm.endtime = $$v;
      },
      expression: "endtime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("不限")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("自定义")])], 1)], 1), _c("div", [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["end_time", {
        rules: [{
          required: _vm.endtime == 1,
          message: "请选择结束时间"
        }],
        initialValue: !_vm.info.end_time ? null : _vm.moment(_vm.info.end_time, "YYYY-MM-DD HH:mm:ss")
      }],
      expression: "[\n                        'end_time',\n                        {\n                            rules: [\n                                {\n                                    required: endtime == 1,\n                                    message: '请选择结束时间'\n                                },\n                            ],\n                            initialValue: !info.end_time ? null : moment(info.end_time, 'YYYY-MM-DD HH:mm:ss')\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      format: "YYYY-MM-DD HH:mm:ss",
      "disabled-date": _vm.disabledDate,
      showTime: true,
      disabled: _vm.isFormDisabled(),
      placeholder: "年月日时分秒"
    }
  })], 1)]), _c("a-form-item", {
    staticClass: "content-line1"
  }, [_c("label", {
    staticClass: "ant-form-item-required",
    attrs: {
      slot: "label",
      title: "加券配置"
    },
    slot: "label"
  }, [_vm._v("加券配置")]), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: true,
      checked: true
    }
  }), _vm._v(" 参与活动奖励1奖券 ")], 1), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      checked: _vm.ticketConfig.app_download == 3
    },
    on: {
      change: function change(e) {
        _vm.clicked(e, "ticketConfig", "app_download");
      }
    }
  }), _vm._v(" 下载App奖励3奖券 ")], 1), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      checked: _vm.ticketConfig.share > 0
    },
    on: {
      change: function change(e) {
        _vm.clicked(e, "ticketConfig", "share");
      }
    }
  }), _vm._v(" 分享活动到社交平台每个渠道奖励1奖券，个人上限 "), _c("a-input-number", {
    attrs: {
      min: 1,
      max: 4,
      disabled: _vm.isFormDisabled()
    },
    model: {
      value: _vm.share,
      callback: function callback($$v) {
        _vm.share = $$v;
      },
      expression: "share"
    }
  })], 1), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      checked: _vm.ticketConfig.invite_friend > 0
    },
    on: {
      change: function change(e) {
        _vm.clicked(e, "ticketConfig", "invite_friend");
      }
    }
  }), _vm._v(" 邀请好友每次成功奖励1奖券，个人上限 "), _c("a-input-number", {
    attrs: {
      min: 1,
      max: 99999999,
      disabled: _vm.isFormDisabled()
    },
    model: {
      value: _vm.invite_friend,
      callback: function callback($$v) {
        _vm.invite_friend = $$v;
      },
      expression: "invite_friend"
    }
  })], 1)]), _c("a-form-item", {
    staticClass: "content-line1"
  }, [_c("label", {
    staticClass: "ant-form-item-required",
    attrs: {
      slot: "label",
      title: "展示"
    },
    slot: "label"
  }, [_vm._v("展示")]), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isView,
      checked: _vm.showConfig.ticket_num_show == 1
    },
    on: {
      change: function change(e) {
        _vm.clicked(e, "showConfig", "ticket_num_show");
      }
    }
  }), _vm._v(" 奖券发放量+总量 ")], 1), _c("div", [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isView,
      checked: _vm.showConfig.win_probability == 1
    },
    on: {
      change: function change(e) {
        _vm.clicked(e, "showConfig", "win_probability");
      }
    }
  }), _vm._v(" 中奖概率 ")], 1)]), _c("div", {
    staticClass: "combinbtn"
  }, [!_vm.isView ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["lottery:btn:submit"],
      expression: "['lottery:btn:submit']"
    }],
    staticStyle: {
      width: "90px",
      height: "40px",
      background: "#3270f5",
      "border-radius": "6px",
      color: "#ffffff",
      border: "0"
    },
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };